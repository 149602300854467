import $ from 'jquery';

const wind = $(window);
const body = $('body');
const bodyHTML = $('body, html');

let scrollPosi;

function bodyFix() {
  scrollPosi = $(window).scrollTop();

  body.addClass('is-fixed').css({ top: -scrollPosi });
}

function bodyFixReset() {
  body.removeClass('is-fixed').css({ top: '0' });

  bodyHTML.scrollTop(scrollPosi);
}


$(window).on('pageshow', function (event) {
  if (
    event.persisted ||
    (typeof window.performance != 'undefined' &&
      window.performance.navigation.type === 2)
  ) {
    // Handle page restore.
    // window.location.reload();
  }
});

$(window).on('load scroll', function () {
  $('#header').css('left', $(this).scrollLeft() * -1);
});

// menu sp
$('#header-toggle').on('click', function () {
  let body = $('#header-body');
  if (body.length) {
    $(this).toggleClass('is-active');
    $('#header').toggleClass('is-nav-show');
    body.toggleClass('is-active');
    body.slideToggle(300);

    if ($(this).hasClass('is-active')) {
      bodyFix();
    } else {
      bodyFixReset();
    }
  }
});

$('.c-nav1__link').on('click', () => {
  if($(window).outerWidth() < 769){
    resetMenuHeader();
    bodyFixReset();
  }
});

$(window).bind('pageshow', function (event) {
  if (event.originalEvent.persisted) {
    bodyFixReset();
    resetMenuHeader();
  }
});

function resetMenuHeader() {
  $('#header').removeClass('is-nav-show');
  $('#header-toggle').removeClass('is-active');
  $('.c-header__navBody').removeClass('is-active').hide();
  $('.is-sub .c-header__link').removeClass('is-active');
  $('.is-sub .c-header__link').next('.c-nav1').hide();
}

// sub menu sp
$('.is-sub .c-header__link').on('click', function () {
  if ($(window).width() <= 768) {
    if ($(this).hasClass('is-active')) {
      $(this).next('.c-nav1').slideUp();
      $(this).removeClass('is-active');
    } else {
      $(this).addClass('is-active');
      $(this).next('.c-nav1').slideDown();
    }
  }
});

// slider top
$('.js-swiper').each(function () {
  new Swiper(this, {
    slidesPerView: 1,
    loop: true,
    autoplay: {
      delay: 5000,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
  });
});

$('body').addClass('loaded');

// QA
$('.js-accor').on('click', function () {
  $(this).next('.js-accon-cont').slideToggle();
  $(this).children('.js-accon-icon').toggleClass('is-active');
});

// popup

(() => {
  const itemModal = $('.js-modal');
  const closeModal = $('.js-close-modal');
  const modal = $('.c-modal01');

  if (itemModal.length) {
    itemModal.each(function () {
      $(this).on('click', function () {
        const dataModal = $(this).data('modal');

        if (modal.length) {
          $(`.c-modal01[data-modal=${dataModal}]`).removeClass('is-hidden');
        }

        bodyFix();

        const hWindow = wind.innerHeight() - 120;
        const hModalInner = $(
          `.c-modal01[data-modal=${dataModal}] .c-modal01__inner`,
        ).innerHeight();

        if (hWindow < hModalInner) {
          modal.addClass('item-start');
        } else {
          modal.removeClass('item-start');
        }
      });
    });

    closeModal.on('click', () => {
      modal.addClass('is-hidden');
      bodyFixReset();
    });

    wind.on('resize', () => {
      const hWindow = wind.innerHeight() - 120;
      const hModalInner = $(
        '.c-modal01:not(.is-hide) .c-modal01__inner',
      ).innerHeight();

      if (hWindow < hModalInner) {
        modal.addClass('item-start');
      } else {
        modal.removeClass('item-start');
      }
    });
  }
})();

$(function () {
  /* スムーズスクロール
    /*---------------------------------------------------------*/
  $('a[href*="#"]').on('click', function (e) {
    let href = this.hash;
    let speed = 800; // ミリ秒
    let target = $(href === '#' || href === '' ? 'html' : href);
    let locationPath = window.location.pathname.replace(/\//g, '');
    let thisPath = this.pathname.replace(/\//g, '');
    if (target.length && locationPath === thisPath) {
      e.preventDefault();
      let headerHeight = $('#header').innerHeight();
      let targetPosition = target.offset().top;
      let scrollStop = function () {
        $('body, html').stop(true);
      };
      $('body, html').animate(
        { scrollTop: targetPosition - headerHeight },
        speed,
      );
      window.addEventListener('DOMMouseScroll', scrollStop, false);
      window.onmousewheel = document.onmousewheel = scrollStop;
    }
  });
});

$(window).on('load', function () {
  /* URL要素に移動
    /*---------------------------------------------------------*/
  (function () {
    let localLink = window.location + '';
    if (localLink.indexOf('#') !== -1) {
      let headerHeight = $('#header').innerHeight();
      localLink = localLink.slice(localLink.indexOf('#') + 1);
      $('html,body').animate(
        { scrollTop: $('#' + localLink).offset().top - headerHeight },
        500,
        'swing',
      );
    }
  })();
});
